.sub-title {
	margin: 0 0 1rem 0;
}

.errorMessage {
	margin: 0.7rem 0 0 0;
}

.return-login {
	display: block;
	margin: 1rem 0 0 0;
}
