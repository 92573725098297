footer {
	border-top: 1px solid #f1f2f2;
}

.level {
	display: flex;
}

@media screen and (max-width: 768px) {
	.level span {
		display: none;
	}
	.levelRight {
		margin-top: 0 !important;
	}
}
